import React, { useState, useEffect, useRef } from "react";
import { navigateTo } from "gatsby";
import axios from "../../../utils/request";
import PublisherHeader from "../../../components/publish-header";
import Cursor from "../../../components/cursor";
import { genImagePath } from "../../../utils/genImagePath";
import PreviewImg from "../../../components/preview-img";
import style from "./designerIndex.module.css";

export default ({ location }) => {
  const [index, setIndex] = useState(0);
  const [profile, setProfile] = useState({});
  const [userinfo, setUserInfo] = useState({});
  const [showEllipsis, setShowEllipsis] = useState(false);
  const [preLook, setPreLook] = useState(false);
  const nameEl = useRef(null);

  function handleLook(item) {
    const name = item.name.toLowerCase();
    const IS_IMAGE = name.endsWith("png") || item.name.endsWith("jpg") || name.endsWith("jpeg");
    if (IS_IMAGE) {
      setPreLook(true);
    } else {
      let link = document.createElement("a");
      link.href = genImagePath(item.url).replace("-internal.aliyuncs", ".aliyuncs");
      link.target = "__blank";
      document.body.appendChild(link);
      link.click();
      console.log(link.href);
      document.body.removeChild(link);
    }
  }

  useEffect(() => {
    async function getDetail() {
      const { data } = await axios({
        method: "get",
        url: "/designer/profile",
        headers: { "Content-Type": "application/json;charset=UTF-8" },
      });
      // console.log(data);
      if (data) setProfile(data);
    }

    async function getUserinfo() {
      const { data } = await axios({
        method: "get",
        url: "/designer/userinfo",
        headers: { "Content-Type": "application/json;charset=UTF-8" },
      });
      // console.log(data);
      if (data) setUserInfo(data);
    }

    getDetail();
    getUserinfo();
  }, []);

  useEffect(() => {
    if (nameEl?.current?.offsetHeight >= 36) {
      setShowEllipsis(true);
    }
  }, [index]);

  return (
    <div className={style.registerWrap}>
      <Cursor></Cursor>
      <PublisherHeader
        pathName={location.pathname}
      ></PublisherHeader>
      <div className={style.designerBox}>
        <div className={style.designerName}>
          <span>ICY&nbsp;&nbsp;X&nbsp;&nbsp;{userinfo?.jointInfo?.toUpperCase()}</span>
        </div>
        <div className={style.designerAvatar}>
          <div
            style={{
              background: profile?.baseInfo?.PRurl[0]?.url && `url(${genImagePath(profile?.baseInfo?.PRurl[0]?.url)}) no-repeat center`,
            }}
            className={style.avatarImg}>
          </div>
          <ul className={style.designerTab}>
            <li
              onClick={() => setIndex(0)}
              className={`${index === 0 ? style.activeTab : ""}`}
            >
              基本信息
            </li>
            <li
              onClick={() => setIndex(1)}
              className={`${index === 1 ? style.activeTab : ""}`}
            >
              主线品牌
            </li>
            <li
              onClick={() => setIndex(2)}
              className={`${index === 2 ? style.activeTab : ""}`}
            >
              联系方式
            </li>
          </ul>
        </div>
        <div className={style.designerContent}>
          {index === 0 && (
            <div className={style.designerInfo}>
              <h3>{userinfo?.username}</h3>
              <ul className={style.list}>
                <li>
                  <span>性别</span>
                  <span>{profile?.baseInfo?.sex === 1 ? "男" : "女"}</span>
                </li>
                <li>
                  <span>国籍</span>
                  <span>{profile?.baseInfo?.country}</span>
                </li>
                <li>
                  <span>毕业学校</span>
                  <span className={style.onelineEllipsis}>
                    {profile?.baseInfo?.school}
                  </span>
                </li>
                <li>
                  <span>时尚从业经历</span>
                  <span className={style.wordEllipsis}>
                    {profile?.baseInfo?.experience}
                  </span>
                </li>
                <li>
                  <span>时装周经历</span>
                  <span className={style.onelineEllipsis}>
                    {profile?.baseInfo?.fashionWeeks?.map((item, index) => (
                      <i key={index}>
                        {item}时装周
                        {index !== profile?.baseInfo?.fashionWeeks.length - 1 &&
                          " / "}
                      </i>
                    ))}
                  </span>
                </li>
                <li>
                  <span>获奖情况</span>
                  <span className={style.wordEllipsis}>
                    {profile?.baseInfo?.award}
                  </span>
                </li>
              </ul>
            </div>
          )}

          {index === 1 && (
            <div className={style.designerInfo}>
              <h3>{userinfo?.username}</h3>
              <ul className={style.list}>
                <li>
                  <span>品牌介绍</span>
                  <span>{profile?.brandInfo?.introduction}</span>
                </li>
                <li>
                  <span>风格</span>
                  <span>
                    {profile?.brandInfo?.styles?.map((item, index) => (
                      <i key={index}>
                        {item}
                        {index !== profile?.brandInfo?.styles.length - 1 && "/"}
                      </i>
                    ))}
                  </span>
                </li>
                <li>
                  <span>官方网站</span>
                  <span>
                    {profile?.brandInfo?.urls?.filter(
                      (item) => item.type === "offical"
                    )[0].value || "-"}
                  </span>
                </li>
                <li>
                  <span>LOGO</span>
                  <span>
                    <div className={style.file}>
                      {
                        profile?.baseInfo?.logo[0]?.name ? (
                          <>
                            <div className={style.fileTop}>
                              <div className={style.mask}>
                                <img
                                  src={require("../../../img/icon_eye.png")}
                                  onClick={() => handleLook(profile?.baseInfo?.logo[0])}
                                  width="22"
                                  height="15"
                                  alt="" />
                              </div>
                              {profile?.baseInfo?.logo[0]?.name?.lastIndexOf(".")
                                ? profile?.baseInfo?.logo[0].name
                                  .slice(
                                    profile?.baseInfo?.logo[0]?.name?.lastIndexOf(
                                      "."
                                    ) + 1
                                  )
                                  .toUpperCase()
                                : ""}
                            </div>
                            <div className={style.fileName}>
                              <i ref={nameEl}>{profile?.baseInfo?.logo[0].name}</i>
                              {showEllipsis && (
                                <i>
                                  ...
                                  {profile?.baseInfo?.logo[0].name.slice(
                                    profile?.baseInfo?.logo[0]?.name?.lastIndexOf(".")
                                  )}
                                </i>
                              )}
                            </div>
                          </>
                        ) : "-"
                      }
                    </div>
                  </span>
                </li>
              </ul>
            </div>
          )}

          {index === 2 && (
            <div className={style.designerInfo}>
              <h3>{userinfo?.username}</h3>
              <ul className={style.list}>
                <li>
                  <span>手机号</span>
                  <span>{userinfo?.phone}</span>
                </li>
                <li>
                  <span>微信</span>
                  <span>{userinfo?.wechat}</span>
                </li>
                <li>
                  <span>邮箱</span>
                  <span>{userinfo?.email}</span>
                </li>
                <li>
                  <span>商务联系人</span>
                  <span>
                    {profile?.businessContact?.username}
                    <i style={{ marginLeft: "20px" }}>
                      手机号：
                      {profile?.businessContact?.phone}
                    </i>
                  </span>
                </li>
                <li>
                  <span>办公地址</span>
                  <span>
                    {profile?.workAddr?.country +
                      profile?.workAddr?.province +
                      profile?.workAddr?.city +
                      profile?.workAddr?.address}
                  </span>
                </li>
              </ul>
            </div>
          )}
          <span
            className={style.edit}
            onClick={() => navigateTo("/designerPress/fullInfo?edit=1")}
          >
            <img
              width="640"
              height="360"
              src={require("../../../img/icon_edit.png")}
              alt="icy编辑图标"
            ></img>
            编辑
          </span>
        </div>
      </div>
      <PreviewImg isClose={preLook} isCloseFn={setPreLook} url={profile?.baseInfo?.logo[0]?.url}></PreviewImg>
    </div>
  );
};
